import React from "react"
import VideoPlayerJS from './../components/videoplayer';
import Layout from "../components/Layout/layout"

const VideoPage = ({ videoId }) => {
  return <Layout page={"about"}>
        <div className="main">
	        <div 
	        style={{
	            width: '98vw', 
	            height: '75vh', 
	            display: 'flex',
	            flexDirection: 'column', 
	            alignItems: 'center', 
	            justifyContent: 'center'
	     	}}>
		        <div>
		            <VideoPlayerJS
		                autoplay={false}
		                width={"640px"}
		                height={"360px"}
		                id={videoId}
		              />  
		        </div>
			</div>
		</div>
		</Layout>	
}

export default VideoPage